<template>
  <div>
    <div class="card border-0 bg-light shadow-none mb-4">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-auto my-auto">
            <h6 class="fw-bold mb-0">Room Booking ID #{{ rb.id }}</h6>
          </div>
          <div class="col my-auto my-auto">
            <span class="mb-0"
              ><small>Created: {{ rb.created_at | formatDate }}</small></span
            >
          </div>
          <div class="col-auto my-auto">
            <button class="btn btn-outline-success btn-sm" @click="openNotes">
              <i class="far fa-notes-medical me-2"></i>Notes
            </button>
          </div>
          <div class="col-auto my-auto ms-auto">
            <button
              class="btn btn-warning btn-sm"
              v-if="editing"
              @click="editing = false"
            >
              <i class="fas fa-stop me-2"></i>Stop Editing
            </button>
          </div>
          <div class="col-auto my-auto">
            <div class="dropdown">
              <button
                class="btn btn-secondary btn-sm"
                type="button"
                id="manageRoomBooking"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Manage<i class="far fa-ellipsis-v ms-2"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="manageRoomBooking">
                <li>
                  <button class="dropdown-item" @click="editing = !editing">
                    Edit Booking
                  </button>
                </li>

                <li>
                  <button
                    class="dropdown-item"
                    @click="viewingPayments = !viewingPayments"
                  >
                    Payments
                  </button>
                </li>

                <li v-if="b.retreat == 'uk'">
                  <button class="dropdown-item" @click="viewBilling(b)">
                    Internal Billing
                  </button>
                </li>

                <li>
                  <button class="dropdown-item" @click="cancelRoomBooking">
                    Cancel Room Booking
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="!viewingPayments">
          <div class="row mb-3">
            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Arrival</h6>
                  <h5 class="text-success fw-bold">
                    {{ rb.arrival_date | formatDate }}
                  </h5>

                  <input
                    v-if="editing"
                    type="date"
                    v-model="rb.arrival_date"
                    class="form-control mt-2"
                  />
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Nights</h6>
                  <h5 class="text-success fw-bold">
                    {{ rb.duration }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Departure</h6>
                  <h5 class="text-success fw-bold">
                    {{ rb.departure_date | formatDate }}
                  </h5>

                  <input
                    v-if="editing"
                    type="date"
                    v-model="rb.departure_date"
                    class="form-control mt-2"
                  />
                </div>
              </div>
            </div>

            <!--  -->

            <!--  -->

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Payment Status</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.payment_status | formatStatus }}
                  </h5>

                  <select
                    v-if="editing"
                    v-model="rb.payment_status"
                    class="form-control mt-2"
                  >
                    <option value="" disabled>Select payment status</option>
                    <option value="paid">Paid</option>
                    <option value="partial_payment">Partial Payment</option>
                    <option value="not_paid">Not Paid</option>
                    <option value="cancelled" disabled>Cancelled</option>
                  </select>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Booking Status</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.type | formatStatus }}
                  </h5>

                  <select
                    v-if="editing"
                    v-model="rb.type"
                    class="form-control mt-2"
                  >
                    <option value="" disabled>Select type</option>
                    <option value="booked">Booked</option>
                    <option value="reserved">Reserved</option>
                    <option value="pending">Pending</option>
                    <option value="postponed">Postponed</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Room</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.room.name + " / " + rb.room.floor }}
                  </h5>

                  <select
                    v-if="editing"
                    v-model="rb.room_id"
                    class="form-control mt-2"
                  >
                    <option value="" disabled>Select room</option>
                    <option v-for="r in retreatRooms" :key="r.id" :value="r.id">
                      {{ r.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center" v-if="!editing">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Room Type</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.room.type }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Room Config</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.room_config }}
                  </h5>
                  <select
                    v-if="editing"
                    v-model="rb.room_config"
                    class="form-control mt-2"
                  >
                    <option value="" disabled>Select room</option>
                    <option value="individual">Individual</option>
                    <option value="shared">Shared</option>
                    <option value="dual" disabled>Dual</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3 text-center" v-if="!editing">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Cost</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ rb.total_cost.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center" v-if="!editing">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Paid</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ rb.total_paid.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 mb-3 text-center">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Outstanding</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ parseInt(rb.total_outstanding).toFixed(2) }}
                  </h5>
                  <input
                    v-if="editing"
                    type="number"
                    v-model="rb.total_outstanding"
                    class="form-control mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <div class="row mb-4" v-if="editing">
            <div class="col-auto ms-auto">
              <button class="btn btn-success btn-lg" @click="updateBasic">
                <i class="far fa-save me-2"></i>Save changes
              </button>
            </div>
          </div>

          <!-- Client Info -->

          <div class="row">
            <div class="col-6" v-for="c in rb.clients" :key="c.id">
              <div class="card border-0">
                <div class="card-body">
                  <div class="row" v-if="editing">
                    <div class="col-auto ms-auto">
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click="removeClientFromBooking(c)"
                      >
                        <i class="far fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <h5 class="text-success fw-bold mb-0">{{ c.name }}</h5>

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">
                          Booking Confirmation Sent
                        </div>
                        <div class="col-auto text-center ms-auto my-auto">
                          <i class="far fa-check"></i>
                        </div>
                        <div class="col-auto">
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-secondary btn-sm"
                              type="button"
                              id="bookingEmailsDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="far fa-ellipsis-v"></i>
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="bookingEmailsDropdown"
                            >
                              <li>
                                <div
                                  class="dropdown-item"
                                  @click="sendBookingConfirmation(c.id, b.id)"
                                >
                                  Send Booking Confirmation Email
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">Retreat Info Sent</div>
                        <div class="col-auto text-center ms-auto my-auto">
                          <i class="far fa-check"></i>
                        </div>
                        <div class="col-auto">
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-secondary btn-sm"
                              type="button"
                              id="retreatInfoDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="far fa-ellipsis-v"></i>
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="retreatInfoDropdown"
                            >
                              <li>
                                <div
                                  class="dropdown-item"
                                  @click="sendRetreatInfoEmail(c.id, rb.id)"
                                >
                                  Send Retreat Information Email
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">
                          Health Questionnaire
                        </div>
                        <div class="col-auto text-center ms-auto my-auto">
                          <i class="far fa-check"></i>
                        </div>
                        <div class="col-auto">
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-secondary btn-sm"
                              type="button"
                              id="healthQuestionnairesDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="far fa-ellipsis-v"></i>
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="healthQuestionnairesDropdown"
                            >
                              <li>
                                <div
                                  class="dropdown-item"
                                  @click="sendHqRequest(c.id, rb.id)"
                                >
                                  Send HQ Request
                                </div>
                              </li>

                              <li>
                                <a class="dropdown-item" href="#"
                                  >Create an HQ for the Client</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">Treatments Booked</div>
                        <div class="col-auto text-center ms-auto my-auto">
                          <i class="far fa-times"></i>
                        </div>
                        <div class="col-auto">
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-secondary btn-sm"
                              type="button"
                              id="treatmentsDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="far fa-ellipsis-v"></i>
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="treatmentsDropdown"
                            >
                              <li>
                                <a class="dropdown-item" href="#"
                                  >Book treatments for Client</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr />

                  <div class="row">
                    <div class="col">
                      <small
                        class="text-success fw-bold cursor-pointer"
                        v-tooltip="'Click to copy'"
                        v-clipboard:copy="c.email"
                        >{{ c.email }}</small
                      >
                    </div>
                    <div class="col text-end">
                      <small
                        class="text-success fw-bold cursor-pointer"
                        v-tooltip="'Click to copy'"
                        v-clipboard:copy="c.mobile ? c.mobile : c.phone"
                        >{{
                          c.mobile ? c.mobile : c.phone ? c.phone : "no number"
                        }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-6 d-flex align-items-center justify-content-center"
              v-if="rb.clients.length == 1"
            >
              <div class="text-center" v-if="!addNewClient">
                <div
                  class="card border-0 shadow-sm cursor-pointer"
                  @click="addNewClient = true"
                >
                  <div class="card-body">
                    <div class="mb-4">
                      <i class="far fa-plus text-success fw-bold fa-4x"></i>
                    </div>

                    <h4 class="fw-bold text-success mb-0">Add 2nd Client</h4>
                  </div>
                </div>
              </div>

              <div class="w-100" v-else>
                <form class="d-flex" @submit.prevent>
                  <input
                    class="form-control me-2"
                    type="search"
                    v-model="clientSearch"
                    @input="searchForClient"
                    placeholder="Search"
                    aria-label="Search"
                    v-if="$can('view client profiles')"
                  />
                </form>
                <div
                  class="bg-white shadow-sm mt-3"
                  style="max-height: 600px; overflow: scroll"
                  v-if="clients.length > 0"
                >
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item cursor-pointer"
                      v-for="c in clients"
                      :key="c.id"
                      @click="selectClientAsSecond(c)"
                    >
                      {{ c.name }} / {{ c.email }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <payment-details
          v-if="viewingPayments"
          @close="closePayments"
          :b="b"
          :rb="rb"
        ></payment-details>
      </div>
    </div>
    <notes-modal
      v-show="showNotes"
      :model="'bookings/room-bookings'"
      :model-id="rb.id"
      @close="closeNotes"
    ></notes-modal>
  </div>
</template>

<script>
import PaymentDetails from "./PaymentDetails";
import NotesModal from "../../notes/NotesModal";
import { Modal } from "bootstrap";

export default {
  props: ["b", "rb"],
  data() {
    return {
      viewingPayments: false,
      editing: false,
      rooms: [],
      //
      addNewClient: false,
      clientSearch: "",
      clients: [],
      //
      showNotes: false
    };
  },
  computed: {
    retreatRooms() {
      return this.rooms.filter(i => {
        return i.retreat == this.b.retreat;
      });
    }
  },
  methods: {
    openNotes() {
      this.showNotes = true;
      var notesModal = new this.$bootstrap.Modal(
        document.getElementById("notesViewModal")
      );
      notesModal.show();
    },
    closeNotes() {
      this.showNotes = false;
    },
    selectClientAsSecond(client) {
      if (
        confirm(
          "Are you sure you wish to add the selected client " +
            client.name +
            " to this room booking? No emails are issued and no price change is made, this must be done manually!"
        )
      ) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/bookings/room-bookings/" +
              this.rb.id +
              "/add-client",
            { client_id: client.id, booking_id: this.b.id }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", { message: data.message });
            this.$emit("close");
          });
      }
    },

    removeClientFromBooking(client) {
      if (
        confirm(
          "Are you sure you wish to remove the selected client " +
            client.name +
            " from this room booking? No emails are issued and no price change is made, this must be done manually!"
        )
      ) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/bookings/room-bookings/" +
              this.rb.id +
              "/remove-client",
            { client_id: client.id, booking_id: this.b.id }
          )
          .then(({ data }) => {
            if (data.removed) {
              this.$EventBus.$emit("alert", { message: data.message });
              this.$emit("close");
            } else {
              this.$EventBus.$emit("alert", { message: data.message });
            }
          });
      }
    },
    searchForClient() {
      if (this.clientSearch.length >= 3) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/clients/search", {
            search: this.clientSearch
          })
          .then(({ data }) => {
            this.clients = data;
          });
      }
    },
    cancelRoomBooking() {
      if (
        confirm(
          "Are you sure you wish to cancel this booking?! The client will NOT be notified of this, they may login and see the change in their portal"
        )
      ) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/bookings/" +
              this.b.id +
              "/cancel-room-booking",
            this.rb
          )
          .then(({ data }) => {
            console.log(data);
            this.$EventBus.$emit("alert", { message: data.message });
            this.$emit("close");
          });
      }
    },
    updateBasic() {
      if (
        confirm(
          "Are you sure you wish to save the changes you have made to this booking?"
        )
      ) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/bookings/" +
              this.b.id +
              "/update-basic",
            this.rb
          )
          .then(({ data }) => {
            console.log(data);
            this.$EventBus.$emit("alert", { message: data.message });
            this.$emit("close");
          });
      } else {
        this.editing = false;
      }
    },
    updateRoom() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/bookings/" +
            this.b.id +
            "/update-room",
          {
            room_id: this.rb.room_id,
            room_booking: this.rb.id
          }
        )
        .then(({ data }) => {
          console.log(data);
          this.$EventBus.$emit("alert", { message: data.message });
          this.$emit("close");
        });
    },
    viewBilling(b) {
      this.$router.push("/clients/" + b.lead_client_id + "/billing/" + b.id);;
    },
    fetchRooms() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/rooms")
        .then(({ data }) => {
          this.rooms = data;
        });
    },
    sendBookingConfirmation(c, b) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/bookings/" +
            b +
            "/" +
            c +
            "/send-confirmation"
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    sendRetreatInfoEmail(c, rb) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/bookings/room-bookings/" +
            rb +
            "/" +
            c +
            "/send-retreat-info"
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    sendHqRequest(c, rb) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/bookings/room-bookings/" +
            rb +
            "/" +
            c +
            "/send-hq-request"
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    closePayments() {
      this.viewingPayments = false;
    }
  },
  mounted() {
    this.fetchRooms();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("ddd LL");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
  components: {
    PaymentDetails,
    NotesModal
  }
};
</script>

<style></style>
