<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h3 class="fw-bold text-success mb-4">
              Book treatments for the client
            </h3>
            <form @submit.prevent>
              <div class="row">
                <div class="col-md-4 my-auto">
                  <label for="">Select room booking</label>
                  <select
                    v-model="form.roomBooking"
                    class="form-control text-capitalize"
                  >
                    <option value="" disabled>Select a booking...</option>
                    <option
                      v-for="rbrb in roomBookings"
                      :key="rbrb.id"
                      :value="rbrb"
                    >
                      Booking #{{ rbrb.id }} - {{ rbrb.booking.retreat }} -
                      {{ rbrb.arrival_date }} /
                      {{ rbrb.departure_date }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4 my-auto">
                  <label for="">Choose treatment booking type</label>
                  <select v-model="form.bundle" class="form-control">
                    <option value="" disabled>Select type of booking</option>
                    <option value="no_bundle">
                      No Bundle - Items priced individually
                    </option>
                    <option
                      v-if="form.roomBooking && form.roomBooking.booking.retreat == 'uk'"
                      value="bundle"
                    >
                      Bundle - £640 for 10 treatment credits
                    </option>
                    <option
                      v-if="form.roomBooking && form.roomBooking.booking.retreat == 'malta'"
                      value="bundle"
                    >
                      Bundle - €700 for 10 treatment credits
                    </option>
                  </select>
                </div>

                <div
                  class="col-auto ms-auto my-auto"
                  v-if="form.bundle == 'bundle'"
                >
                  <div class="card border-0 bg-light shadow-none">
                    <div class="card-body">
                      <p class="mb-0">Credits remaining</p>
                      <h4 class="fw-bold text-success mb-0">
                        {{ form.credits }}
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-auto ms-auto my-auto" v-else>
                  <div class="card border-0 bg-light shadow-none">
                    <div class="card-body">
                      <p class="mb-0">Total</p>
                      <h4 class="fw-bold text-success mb-0">
                        {{ form.roomBooking && form.roomBooking.booking.retreat == "malta" ? "€" : "£" }}{{ form.total.toFixed(2) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <!--  -->
              <div class="row mt-4" v-if="form.roomBooking && form.bundle">
                <div class="col-md-4">
                  <div class="card border-0 bg-light shadow-sm">
                    <div class="card-header bg-light border-0">
                      <h5 class="fw-bold text-success">Select treatments</h5>
                    </div>
                    <div class="card-body pt-0">
                      <ul class="list-group list-group-flush cursor-pointer">
                        <li
                          class="list-group-item"
                          v-for="t in availableTreatments"
                          :key="t.id"
                          @click="addTreatment(t)"
                        >
                          <div class="row">
                            <div class="col-2 my-auto">
                              <img :src="t.image" :alt="t.title" width="100%" />
                            </div>
                            <div class="col my-auto">
                              <h6 class="fw-bold mb-0">
                                {{ t.title }}
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!--  -->

                <div class="col">
                  <div class="card border-0 bg-light shadow-sm">
                    <div class="card-body">
                      <div
                        v-if="form.treatments.length == 0"
                        class="text-center"
                      >
                        <i class="far fa-syringe fa-3x text-success"></i>
                        <p class="fw-bold">
                          The treatments you choose will be listed here, confirm
                          your treatment booking below
                        </p>
                      </div>
                      <div v-else>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item"
                            v-for="(t, key) in form.treatments"
                            :key="key"
                          >
                            <div class="row mb-2">
                              <div class="col-2 my-auto">
                                <img
                                  :src="t.image"
                                  :alt="t.title"
                                  width="100%"
                                />
                              </div>
                              <div class="col my-auto">
                                <h6 class="fw-bold mb-0">
                                  {{ t.title }}
                                </h6>
                                <small v-html="getSummaryFormatted(t)"></small>
                              </div>

                              <div class="col-2 my-auto ms-auto">
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  @change="updateTreatmentQuantity(key, t, $event)"
                                  :value="t.quantity"
                                />
                              </div>
                              <div class="col-auto my-auto">
                                <button
                                  class="btn btn-sm btn-outline-danger"
                                  @click="removeTreatment(key, t)"
                                >
                                  <i class="far fa-times"></i>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <p class="mb-0">
                                  <small>{{ t.description }}</small>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn-success float-end mt-4"
                    @click="saveTreatmentBooking()"
                    :disabled="busy
                      || form.treatments.length == 0
                      || (form.bundle == 'bundle' && form.credits < 0)"
                  >
                    <i class="far fa-check me-2"></i>Confirm Treatment Booking
                  </button>
                </div>
              </div>

              <div class="row my-5" v-else>
                <div class="col">
                  <div class="card border-0 bg-light">
                    <div class="card-body text-center">
                      <i class="far fa-syringe fa-5x text-success my-4"></i>
                      <h5 class="text-success fw-bold mb-3">
                        1. Choose which retreat stay this treatment booking is
                        for
                      </h5>
                      <h5 class="text-success fw-bold mb-3">
                        2. Choose the treatments bundle or individually priced
                        treatments
                      </h5>
                      <h5 class="text-success fw-bold mb-3">
                        3. Choose your desired treatments on the left
                      </h5>
                      <h5 class="text-success fw-bold mb-3">
                        4. Confirm your treatments on the right and modify any
                        quantities as you wish
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roomBookings: [],
      availableTreatments: [],
      form: {
        roomBooking: "",
        booking_id: "",
        bundle: "",
        treatments: [],
        credits: 10,
        client_id: this.$route.params.id,
        total: 0,
      },
      busy: false,
    };
  },
  watch: {
    "form.roomBooking": {
      handler: function (val, oldVal) {
        if (val && val.booking.id) {
          this.fetchAvailableTreatments();
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchRoomBookings() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/room-bookings"
        )
        .then(({ data }) => {
          this.roomBookings = data.filter(
            (rb) => moment(rb.arrival_date) > moment()
          );
        });
    },
    fetchAvailableTreatments() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/treatments?retreat=" + this.form.roomBooking.booking.retreat
        )
        .then(({ data }) => {
          this.availableTreatments = data;
        });
    },
    getSummaryFormatted(t) {
      if (this.form.bundle === 'no_bundle') {
        if (this.form.roomBooking.booking.retreat == "malta" && t.euro_price) {
          return `${t.duration} min session / &euro;${t.euro_price.toFixed(2)}`;
        } else if (t.price) {
          return `${t.duration} min session / &pound;${t.price.toFixed(2)}`;
        } else {
          return `${t.duration} min session`;
        }
      } else {
        return `${t.duration} min session / ${t.credits} credit(s)`;
      }
    },
    addTreatment(t) {
      let newArr = [];
      let found = false;

      if (!t.quantity) {
        t.quantity = 1;
      }

      this.form.treatments.forEach((treatment) => {
        if (t.id === treatment.id) {
          newArr.push(t);
          found = true;
        } else {
          newArr.push(treatment);
        }
      });

      if (!found) {
        newArr.push(t);
      }

      this.form.treatments = newArr;
      this.recalculateTreatmentTotals();
    },
    removeTreatment(key, t) {
      this.form.treatments.splice(key, 1);
      this.recalculateTreatmentTotals();
    },
    updateTreatmentQuantity(key, t, e) {
      t.quantity = parseInt(e.target.value);

      if (t.quantity < 1) {
        this.removeTreatment(key, t);
      } else {
        this.addTreatment(t);
      }
    },
    recalculateTreatmentTotals() {
      let newCred = 0;
      let newTotal = 0;

      this.form.treatments.forEach((treatment) => {
        newCred += (treatment.credits * treatment.quantity);
        newTotal += ((this.form.roomBooking.booking.retreat === "malta" ? treatment.euro_price : treatment.price) * treatment.quantity);
      });

      this.form.credits = 10 - newCred;
      this.form.total = newTotal;
    },
    saveTreatmentBooking() {
      this.busy = true;

      this.form.booking_id = this.form.roomBooking.booking.id;

      if (this.form.bundle == "bundle") {
        this.form.total += this.form.total;
      }

      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/treatment-bookings",
          this.form
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", { message: data.message });
          setTimeout(() => {
            this.$emit("close");

          }, 2000);
        });
    },
  },
  mounted() {
    this.fetchRoomBookings();
  },
};
</script>

<style>
</style>