<template>
  <div class="container fluid">
    <div class="card border shadow-none">
      <div class="card-body">

        <div class="row">
          <div class="col-12 col-md d-flex justify-content-between mb-4">
            <h3 class="fw-bold text-success mb-0 hide-on-print">Billing</h3>

            <h3 class="fw-bold text-success mb-0 show-on-print">Payment due</h3>

            <img src="/logos/small.png" class="logo show-on-print">
          </div>

          <div class="col-auto mb-4 ms-auto hide-on-print">
            <button
               v-if="$can('manage billing')"
              @click="viewBillingIndex()"
              class="btn btn-outline-success me-2"
              :disabled="updating"
            >
              To Billing
            </button>

            <button
              @click="viewClient()"
              class="btn btn-outline-success"
              :disabled="updating"
            >
              To Client
            </button>
          </div>
        </div>

        <loading v-if="loadingClient || loadingClientBookings" class="mb-4"></loading>

        <div v-else>
          <div class="row mb-4 d-flex flex-column">
            <div class="col-12 col-md header">
              {{ client.name }} - Client ID #{{ client.id }}
            </div>
            <div class="col-12 col-md mb-4 header show-on-print">
              Date: {{ new Date().toDateString() }}
            </div>
            <div class="col-12 col-md mb-4 text-capitalize header hide-on-print">
              Status: {{ bill.status }}
            </div>
          </div>

          <div class="mb-1 show-on-print"></div>

          <div id="billing-form" class="m-1">
            <div class="row">
              <div class="col py-1 fw-bold hide-on-print">Code</div>
              <div class="col-4 description py-1 fw-bold">Description</div>
              <div class="col py-1 fw-bold">Price</div>
              <div
                v-if="bill && bill.status == 'settled' && $can('sync billing')"
                class="col py-1 fw-bold hide-on-print"
              >
                Sales account
              </div>
              <div class="col py-1 fw-bold hide-on-print"></div>
            </div>
            <div v-for="(row, index) in rows" :key="index" class="row">
              <div class="col py-1 hide-on-print">
                <div class="field-group">
                  <div :id="'placeholder_' + index" class="placeholder"></div>
                  <textarea
                    :id="'lookup_' + index"
                    v-model="row.item_code"
                    class="field"
                    @input="onFieldInput"
                    @keydown="onFieldKeyDown"
                    :disabled="
                      (row.item_code.startsWith('UKR') || row.item_code.startsWith('MTR'))
                      ||
                      (bill.status == 'settled' || bill.status == 'synced')
                    "
                  ></textarea>
                </div>
              </div>
              <div class="col-4 description py-1">
                <div class="field-group">
                  <div class="placeholder"></div>
                  <textarea
                  :id="'description_' + index"
                  v-model="row.description"
                  class="field"
                  :disabled="
                    (row.item_code.startsWith('UKR') || row.item_code.startsWith('MTR'))
                    ||
                    (bill.status == 'settled' || bill.status == 'synced')
                  "
                  ></textarea>
                </div>
              </div>
              <div class="col py-1">
                <div class="field-group">
                  <div class="placeholder"></div>
                  <span
                    v-if="(row.item_code.startsWith('UKR') || row.item_code.startsWith('MTR'))"
                    class="field"
                    style="color: #6d6d6d"
                  >
                    Paid
                  </span>
                  <input
                    v-else
                    :id="'price_' + index"
                    v-model="row.price"
                    type="number"
                    class="field"
                    min="1"
                    step="any"
                    @change="formatNumber($event, 'price_' + index)"
                    :disabled="
                      (bill.status == 'settled' || bill.status == 'synced')
                    "
                  />
                </div>
              </div>
              <div
                v-if="bill && bill.status == 'settled' && $can('sync billing')"
                class="col py-1 hide-on-print"
              >
                <div class="field-group">
                  <div class="placeholder"></div>
                    <input
                      :id="'sales_account_' + index"
                      v-model="row.sales_account"
                      type="number"
                      class="field"
                      min="1"
                      step="1"
                      @change="setDirty($event)"
                      :disabled="
                        (row.item_code.startsWith('UKR') || row.item_code.startsWith('MTR'))
                        ||
                        bill.status == 'synced'"
                    />
                </div>
              </div>
              <div
                class="col py-1 hide-on-print"
              >
                <button
                  v-if="bill.status != 'settled' && bill.status != 'synced'"
                  class="btn btn-sm me-2"
                  tabindex="-1"
                  @click="addRow(index)"
                >
                  <i class="far fa-plus"></i>
                </button>
                <button
                  v-if="bill.status != 'settled' && bill.status != 'synced'"
                  class="btn btn-sm"
                  tabindex="-1"
                  @click="removeRow(index)"
                >
                  <i class="far fa-minus"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 100px;">
            <div class="col-10 py-1">
              <span class="show-on-print">
                This is not a tax invoice.
              </span>
            </div>
            <div class="col py-1">
              <b>Total</b><br/>
              &pound;{{ total.toFixed(2) }}
            </div>
          </div>

          <div
            class="p-3 px-0 show-on-print"
            style="margin-top: 100px;"
          >
            <p>
              Thank you for staying with Amchara.
            </p>

            <p>
              Your bill is due to be settled this afternoon. Please speak to a
              member of the team who will be able to assist you.
            </p>

            <p><i>Amchara - Change for Good</i></p>
          </div>

        </div>

        <div v-if="!loadingClient && !loadingClientBookings" style="margin-top: 50px;">
          <table>
            <tr>
              <td>
                <button
                  v-if="bill
                  && (
                    (bill.status != 'settled' && bill.status != 'synced')
                    ||
                    (bill.status != 'synced' && $can('sync billing'))
                  )"
                  @click="updateClientBill()"
                  class="btn btn-outline-success me-2"
                  :disabled="updating"
                >
                  Save
                </button>
              </td>
              <td>
                <button
                  v-if="bill && bill.status == 'open'"
                  @click="updateClientBill('approved')"
                  class="btn btn-outline-success me-2"
                  :disabled="updating || dirty"
                >
                  Approve
                </button>

                <div
                  v-if="bill && bill.status == 'approved' && $can('manage billing')"
                  class="d-flex"
                >
                  <select
                    id="settlement_method"
                    class="form-control me-2"
                  >
                    <option value="" selected disabled>Select a payment method</option>
                    <option value="cash">Cash</option>
                    <option value="bacs">BACS</option>
                    <option value="pdq_malta">PDQ - Malta</option>
                    <option value="pdq_uk">PDQ - UK</option>
                  </select>

                  <button
                    @click="updateClientBill('settled')"
                    class="btn btn-outline-success me-2"
                    :disabled="updating || dirty"
                  >
                    Settle
                  </button>
                </div>

                <button
                  v-if="bill && bill.status == 'settled' && $can('sync billing')"
                  @click="syncClientBill()"
                  class="btn btn-outline-success me-2"
                  :disabled="updating || dirty"
                >
                  Sync
                </button>
              </td>
              <td>
                <button
                  @click="print()"
                  class="btn btn-outline-secondary"
                  :disabled="updating"
                >
                  Print
                </button>
              </td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../../components/loaders/Loading.vue';

export default {
  data() {
    return {
      bill: null,
      bookingId: this.$route.params.bookingId,
      client: {},
      clientRetreatBooking: {},
      dirty: false,
      loadingClient: true,
      loadingClientBookings: true,
      rows: [
        { item_code: "", description: "", price: null, sales_account: null },
      ],
      suggestions: [],
      total: 0,
      updating: false
    };
  },
  methods: {
    fetchClient() {
      this.loadingClient = true;

      this.$axios
        .get(process.env.VUE_APP_API_URL + "/clients/" + this.$route.params.id)
        .then(({ data }) => {
          this.client = data;
          this.loadingClient = false;
        })
        .catch((error) => {
          console.error(error);

          this.$EventBus.$emit("alert", {
            color: "danger",
            message: error.message,
          });
        });
    },

    fetchClientBill() {
      this.loadingClientBookings = true;

      this.$axios.get(process.env.VUE_APP_API_URL + "/billing/client/" + this.$route.params.id + "/booking/" + this.bookingId)
        .then(({ data }) => {

          this.bill = data;
          this.rows = [];

          data.items.forEach((item) => {
            if (item.price) {
              item.price = parseFloat(item.price).toFixed(2);
            }
            this.rows.push(item);
          });

          this.recalculateTotal();

          this.loadingClientBookings = false;
        })
        .catch((error) => {
          console.error(error);

          this.$EventBus.$emit("alert", {
            color: "danger",
            message: error.message,
          });
        });
    },

    fetchProductSuggestions() {
      this.$axios(process.env.VUE_APP_API_URL + '/billing/products')
        .then(
          ({ data }) => {
            this.suggestions = data;
          }
        )
        .catch((error) => {
          console.error(error);

          this.$EventBus.$emit("alert", {
            color: "danger",
            message: error.message,
          });
        });
    },

    updateClientBill(status = null) {
      let data = {
        "items": this.rows
      }

      if (status) {
        data["status"] = status;
      }

      if (status == "settled") {
        let method = document.querySelector("#settlement_method").value;

        if (!method) {
          return;
        }

        data["settlement_method"] = method;
      }

      this.updating = true;

      this.$axios.put(
        process.env.VUE_APP_API_URL + "/billing/" + this.bill.id + "/update",
        data
      )
      .then(({ data }) => {
        this.dirty = false;
        this.bill = data;
      })
      .catch((error) => {
        this.$EventBus.$emit("alert", {
          color: "danger",
          message: "Missing required fields"
        });
      })
      .finally(() => this.updating = false);
    },

    syncClientBill() {
      this.updating = true;

      this.$axios.post(
        process.env.VUE_APP_API_URL + "/billing/" + this.bill.id + "/sync"
      )
      .then(({ data }) => {
        this.bill = data;
      })
      .catch((error) => {
        console.error(error);

        this.$EventBus.$emit("alert", {
          color: "danger",
          message: "Something went wrong"
        });
      })
      .finally(() => this.updating = false);
    },

    // Only roombookings atm
    findPrefilledLineItems() {
      this.clientRetreatBooking.room_bookings.forEach((rb) => {
        let productCode = this.getRoomBookingCode(rb);
        let suggestion = this.lookupSuggestion(productCode, true);

        if (suggestion) {
          this.rows.unshift({
            item_code: suggestion.item_code,
            description: suggestion.description ?? "",
            price: suggestion.price ? suggestion.price.toFixed(2) : null,
            sales_account: suggestion.sales_account ?? null
          });

          this.recalculateTotal();
        }
      });
    },

    getRoomBookingCode(roomBooking) {
      let prefix = this.clientRetreatBooking.retreat == "uk" ? "UKR" : "MTR";

      return prefix + "-" + roomBooking.duration + "-" + roomBooking.productConfig;
    },

    onFieldInput(field) {
      this.updateRowValue(field.target ?? field, field.target?.value ?? field.value);
    },

    onFieldKeyDown(e) {
      switch (e.keyCode) {
        case 13:
          e.preventDefault();

          let suggestion = this.lookupSuggestion(e.target.value, true);
          if (suggestion) {
              // Needed for visual reasons
              let fieldIdx = e.target.id.replace("lookup_", "");
              document.getElementById("placeholder_" + fieldIdx).innerText=suggestion.item_code;

              // Fill all fields
              const oldVal = this.rows[fieldIdx];
              Object.assign(oldVal, {
                item_code: suggestion.item_code,
                description: suggestion.description ?? "",
                price: suggestion.price ? suggestion.price.toFixed(2) : null,
                sales_account: suggestion.sales_account ?? null
              });

              this.recalculateTotal();
          }
          break;
      }
    },

    addRow(index) {
      this.rows.splice(index+1, 0, { item_code: "", description: "", price: null });

      document.querySelectorAll("[id^='placeholder_']").forEach((ele) => {
        ele.innerText = "";
      });
console.log(document.querySelectorAll("[id^=lookup_]")?.slice(-1));
      document.querySelectorAll("[id^=lookup_]")?.slice(-1)?.focus();
    },

    removeRow(index) {
      this.rows.splice(index, 1);

      document.querySelectorAll("[id^='placeholder_']").forEach((ele) => {
        ele.innerText = "";
      });

      this.recalculateTotal();
    },

    updateRowValue(field, value) {
      let fieldIdx = field.id.replace("lookup_", "");
      let placeholder = document.getElementById("placeholder_" + fieldIdx);
      value = value.replace(/[\n\r\t]/gi, " ");

      if (value && value.trim()) {
          placeholder.innerText = this.lookupSuggestion(value);
      } else {
        placeholder.innerText = "";
      }

      field.value = value;

      const oldVal = this.rows[fieldIdx];
      Object.assign(oldVal, {
        item_code: value,
        description: oldVal.description,
        price: oldVal.price,
        sales_account: oldVal.sales_account
      });
    },

    lookupSuggestion(value, fullObj = false) {
      let valueToLower = value.toLowerCase();
      let matches = this.suggestions
          .filter(s => s.item_code.toLowerCase().indexOf(valueToLower) === 0)
          .sort();
      if (!matches.length) {
          return value;
      }
      if (fullObj) {
        return matches[0];
      }
      return value + matches[0].item_code.substring(value.length);
    },

    // We have to update using assign due to use of v-model
    formatNumber(e, eleId) {
      document.getElementById(eleId).value = parseFloat(e.target.value).toFixed(2);

      let fieldIdx = e.target.id.replace("price_", "");

      // Fill all fields
      const oldVal = this.rows[fieldIdx];
      Object.assign(oldVal, {
        item_code: oldVal.item_code,
        description: oldVal.description,
        price: oldVal.price ? parseFloat(oldVal.price).toFixed(2) : null,
        sales_account: oldVal.sales_account ?? null
      });

      this.recalculateTotal();
    },

     print() {
      // There's something wrong with the navbar css (see mobile view),
      // which I'm not interested in looking into atm
      document.getElementById("mainNav").style.display = "none";
      window.print();
      document.getElementById("mainNav").style.display = "";
    },

    // After any row-update, recalculate totals
    recalculateTotal() {
      this.dirty = true;
      this.total = 0;

      this.rows.forEach((row) => {
        if (row.price) {
          this.total = parseFloat(this.total) + parseFloat(row.price);
        }
      });
    },

    // If sales_account is updated, force a save first
    setDirty(event) {
      this.dirty = true;
    },

    viewBillingIndex() {
      this.$router.push("/billing");
    },

    viewClient() {
      this.$router.push("/clients/" + this.$route.params.id);
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;

    this.fetchClient();
    this.fetchClientBill();
    this.fetchProductSuggestions();
  },
  components: {
    Loading
  }
};

</script>

<style lang="scss" scoped>
html,
body {
  align-items: center;
  display: flex;
  font-size: 100%;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.logo {
  height: 90px;
  float: right;
}

.header {
  color: #000;
  padding-left: 1rem;
}

.show-on-print {
  display: none;
}

.field-group {
  position: relative;
  min-height: 33px;
  margin-right: 1rem;
  padding: 0.5rem 0;
}

.item_code {
  width: 250px;
}

.description {
  width: 500px;
}

.price,
.controls {
  width: 100px;
}

.placeholder,
.field {
  border: 0;
  height: 100%;
  padding: 0;
  width: 100%;
  left: 0;
  outline: none;
  overflow: hidden;
  position: absolute;
  resize: none;
  top: 0;
  border-bottom: 1px solid #dedede;
}

.placeholder {
  opacity: 0.4;
  z-index: 2;
  user-select: none;
  pointer-events: none;
}

.field {
  z-index: 1;
}

.row-buttons {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}

.btn {
  border: 1px solid #aaa;
}

.far {
  color: #aaa;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .hide-on-print, .btn {
    display: none;
  }

  .show-on-print {
    display: inline-block;
  }

  .description {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .field {
    border-bottom: 1px solid #dedede !important;
  }
}
</style>
