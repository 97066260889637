<template>
  <div class="">
    <div class="card border-0">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col my-auto">
            <h6 class="fw-bold mb-0">Payment details</h6>
          </div>
          <div class="col-auto my-auto ms-auto">
            <button class="btn btn-outline-secondary btn-sm" @click="close">
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>

        <!--  -->

        <div class="row mb-4">
          <div class="col-md-4 text-center">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body">
                <h6 class="fw-bold">Total Cost</h6>
                <h5 class="text-success fw-bold text-capitalize">
                  £{{ roomBooking.total_cost.toFixed(2) }}
                </h5>
              </div>
            </div>
          </div>

          <!--  -->

          <div class="col-md-4 text-center">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body">
                <h6 class="fw-bold">Total Paid</h6>
                <h5 class="text-success fw-bold text-capitalize">
                  £{{ roomBooking.total_paid.toFixed(2) }}
                </h5>
              </div>
            </div>
          </div>

          <!--  -->

          <div class="col-md-4 text-center">
            <div class="card border-0 bg-light shadow-none">
              <div class="card-body">
                <h6 class="fw-bold">Total Outstanding</h6>
                <h5 class="text-success fw-bold text-capitalize">
                  £{{ roomBooking.total_outstanding.toFixed(2) }}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <!-- Set Custom Payment Amount -->
        <div class="card border-0 bg-light shadow-none mb-4">
          <div class="card-body">
            <h6 class="fw-bold text-success">Set custom payable amount</h6>
            <div class="row">
              <div class="col my-auto">
                <input
                  type="number"
                  v-model="roomBooking.override_payment_amount"
                  class="form-control"
                />
              </div>

              <div class="col my-auto">
                <p class="mb-0">
                  <small
                    >If set this will show in the client portal as a payment
                    amount option</small
                  >
                </p>
              </div>

              <div class="col-auto ms-auto my-auto">
                <button class="btn btn-success" @click="updateRoomBooking">
                  <i class="far fa-save me-2"></i>Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- End custom payment amount -->

        <!-- Payment request -->
        <div class="card border-0 bg-light shadow-none mb-4">
          <div class="card-body">
            <h6 class="fw-bold text-success">Send a payment request</h6>
            <form @submit.prevent="sendPaymentRequest">
              <div class="row mb-4">
                <div class="col">
                  <label for="">To which client</label>
                  <select v-model="form.client_id" class="form-control">
                    <option value="" disabled>Select a client</option>
                    <option v-for="c in b.clients" :value="c.id" :key="c.id">
                      {{ c.name }} / {{ c.email }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <label for="">Send request for</label>
                  <select v-model="form.payment_type" class="form-control">
                    <option value="" disabled>Select an option</option>
                    <!-- <option value="room_booking">This Room Booking</option>
                    <option value="retreat_booking">
                      Whole Retreat Booking
                    </option> -->
                    <option value="retreat_booking">Balance</option>
                    <option value="deposit">Deposit</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-6">
                  <div class="col">
                    <label for="">Payment method</label>
                    <select v-model="form.payment_method" class="form-control">
                      <option value="" disabled>Select a method</option>
                      <option value="card">Card</option>
                      <option value="bacs">BACS (transfer)</option>
                      <option value="voucher" disabled>Voucher</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto ms-auto">
                  <button
                    class="btn btn-sm btn-outline-success me-2"
                    :disabled="!canSubmit"
                  >
                    Send Payment Request
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- End payment request -->

        <!-- Record payment -->
        <div class="card border-0 shadow-none bg-light mb-4">
          <div class="card-body">
            <h6 class="fw-bold text-success">Record payment</h6>
            <form @submit.prevent="recordPayment">
              <div class="row mb-4">
                <div class="col">
                  <label for="">From which client</label>
                  <select v-model="paymentForm.client_id" class="form-control">
                    <option value="" disabled>Select a client</option>
                    <option v-for="c in b.clients" :value="c.id" :key="c.id">
                      {{ c.name }} / {{ c.email }}
                    </option>
                  </select>
                </div>

                <div class="col">
                  <label for="">Payment type</label>
                  <select
                    v-model="paymentForm.payment_method"
                    class="form-control"
                  >
                    <option value="" disabled>Select a payment method</option>
                    <option value="cash">Cash</option>
                    <option value="bacs">BACS</option>
                    <option value="pdq_malta">PDQ - Malta</option>
                    <option value="pdq_uk">PDQ - UK</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 my-auto">
                  <label for="">Payment Amount</label>
                  <input
                    type="number"
                    v-model="paymentForm.total"
                    class="form-control"
                  />
                  <p class="mb-0">
                    <small>In pounds for example 100 = £100.00</small>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-auto ms-auto my-auto">
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="
                      paymentForm.total == 0 &&
                      paymentForm.client_id &&
                      paymentForm.payment_method
                    "
                  >
                    <i class="far fa-save me-2"></i>Record payment
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- End record payment -->

        <!-- Transactions -->
        <div class="card border-0 shadow-none bg-light">
          <div class="card-body">
            <h6 class="fw-bold text-success">
              Transactions related to this booking
            </h6>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Total</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in transactions" :key="p.id">
                  <th scope="row">{{ p.id }}</th>
                  <td>£{{ p.total.toFixed(2) }}</td>
                  <td class="text-capitalize">{{ p.type | formatStatus }}</td>
                  <td class="text-capitalize">{{ p.status | formatStatus }}</td>
                  <td>{{ p.created_at | formatDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- End transactions -->
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from 'libphonenumber-js';
export default {
  props: ["b", "rb"],
  data() {
    return {
      transactions: [],
      form: {
        client_id: "",
        payment_type: "",
        payment_method: "",
      },
      paymentForm: {
        client_id: "",
        total: 0,
        payment_method: "",
        retreat_booking_id: this.b.id,
        room_booking_id: this.rb.id,
      },
      roomBooking: this.rb
    };
  },
  computed: {
    canSubmit() {
      if (
        this.form.client_id &&
        this.form.payment_method &&
        this.form.payment_type
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    recordPayment() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/bookings/" +
            this.b.id +
            "/record-payment",
          this.paymentForm
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", { message: data.message });

          this.resetPaymentForm();
        });
    },
    resetPaymentForm() {
      this.roomBooking.total_paid = parseFloat(this.roomBooking.total_paid) + parseFloat(this.paymentForm.total);
      this.roomBooking.total_outstanding = parseFloat(this.roomBooking.total_outstanding) - parseFloat(this.paymentForm.total);

      this.paymentForm.client_id = "";
      this.paymentForm.total = 0;
      this.paymentForm.payment_method = "";

      this.fetchTransactions();
    },
    fetchTransactions() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/bookings/" +
            this.b.id +
            "/transactions"
        )
        .then(({ data }) => {
          this.transactions = data;
        });
    },
    updateRoomBooking() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/bookings/room-bookings/" +
            this.rb.id +
            "/update",
          this.rb
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", { message: data.message });
        });
    },
    sendPaymentRequest() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/bookings/" +
            this.b.id +
            "/send-payment-request",
          this.form
        )
        .then(({ data }) => {
          console.log(data);
          this.form = {
            client_id: "",
            payment_type: "",
            payment_method: "",
          };
          this.$EventBus.$emit("alert", { message: data.message });
        });
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.fetchTransactions();
  },
  filters: {
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<style></style>
