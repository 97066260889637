<template>
  <div class="container fluid">
    <div class="card border shadow-none" v-if="!loading">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">Billing</h3>
          </div>
          <div class="col-2 mb-4">
            <select
              v-model="status"
              @change="fetchBilling()"
              class="form-control me-2"
            >
              <option value="">All statuses</option>
              <option value="open">Open</option>
              <option value="approved">Approved</option>
              <option value="settled">Settled</option>
              <option value="synced">Synced</option>
            </select>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Client</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created by</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="b in bills"
                    :key="b.id"
                    @click="viewBilling(b)"
                    class="cursor-pointer"
                  >
                    <td>{{ b.client.name }}</td>
                    <td style="text-transform: capitalize;">{{ b.status }}</td>
                    <td>{{ b.creator.name }}</td>
                    <td>{{ b.created_at | formatDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="pagination">
              <div class="left">
                <button
                  @click="fetchBilling(pagination.first_page_url)"
                  :disabled="!pagination || !pagination.first_page_url
                    || pagination.current_page == 1"
                  class="btn btn-light btn-sm me-3"
                >
                  First Page
                </button>

                <button
                  @click="fetchBilling(pagination.prev_page_url)"
                  :disabled="!pagination || !pagination.prev_page_url"
                  class="btn btn-light btn-sm"
                >
                  Previous
                </button>
              </div>

              <div class="middle">
                Page {{ pagination ? pagination.current_page : 1 }}
                of
                {{ pagination ? pagination.last_page: 1 }}
              </div>

              <div class="right">
                <button
                  @click="fetchBilling(pagination.next_page_url)"
                  :disabled="!pagination || !pagination.next_page_url"
                  class="btn btn-light btn-sm me-3"
                >
                  Next
                </button>

                <button
                  @click="fetchBilling(pagination.last_page_url)"
                  :disabled="!pagination || !pagination.last_page_url
                    || pagination.current_page == pagination.last_page"
                  class="btn btn-light btn-sm"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading v-else></loading>
  </div>
</template>

<script>
  import DatePicker from "v-calendar/lib/components/date-picker.umd";

  import Loading from "../../components/loaders/Loading";

  export default {
    data() {
      return {
        bills: [],
        loading: true,
        pagination: [],
        range: {
          start: this.$route.query.start ? this.$route.query.start : new Date().setMonth(new Date().getMonth()-1),
          end: this.$route.query.end ? this.$route.query.end : new Date()
        },
        status: ""
      };
    },
    methods: {
      fetchBilling(paginationUrl) {
        let url = process.env.VUE_APP_API_URL + "/billing";

        if (paginationUrl) {
          url = paginationUrl;
        }

        if (this.status) {
          url = this.updateQueryStringParameter(url, "status", this.status);
        }

        url = this.updateQueryStringParameter(url, "start", moment(this.range.start).format("YYYY-MM-DD"));
        url = this.updateQueryStringParameter(url, "end", moment(this.range.end).format("YYYY-MM-DD"));

        this.loading = true;

        this.$axios.get(url).then(({ data }) => {
          this.pagination = data;
          this.bills = data.data;
          this.loading = false;
        });
      },

      updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";

        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      },

      viewBilling(bill) {
        this.$router.push("/clients/" + bill.client_id + "/billing/" + bill.retreat_booking_id);
      },
    },
    mounted() {
      this.$store.sidebarOpen = false;
    },
    created() {
      this.fetchBilling();
    },
    watch: {
      range: {
        handler: function(val, old) {
          this.fetchBilling();
        }
      },
    },
    components: {
      DatePicker,
      Loading
    },
    filters: {
      formatDate(date) {
        return moment(date).format("LL");
      }
    }
  };
</script>

<style>
  .pagination {
    display: flex;
    justify-content: space-between;
  }

  .pagination button {
    margin: 0 0.5rem;
  }
</style>